// The initialize function must be run each time a new page is loaded
Office.onReady(() => {
  document.getElementById("sideload-msg").style.display = "none";
  document.getElementById("app-body").style.display = "flex";
  document.getElementById('login').onclick = getKeySecret;
  // document.getElementById('load_secret').onclick = loadSecret;
  let username =localStorage.getItem("username");
  if (username){
    let title = document.getElementById("user-title");
    title.title = username;
    if (username.length > 12){
      username = username.substring(0, 12) + "...";
    }
    document.getElementById("user-title").innerHTML = "Welcome! " + username;
  }
});

export async function getKeySecret(){
  const config = require("pa_config");
  const url = config.API_URL.toString() + "account/secret/";
  // const url = "https://alfa.plutusanalytics.com/api/account/secret/";
  document.getElementById('login').setAttribute("disabled","true");
  const email = document.getElementById("email-box").value;
  const pass = document.getElementById("pass-box").value;
  if (email && pass){
    document.getElementById('login').setAttribute("disabled","true");
    let xhttp = new XMLHttpRequest();

    return new Promise(function(resolve, reject){
      xhttp.onreadystatechange = function(){
        if (xhttp.readyState !== 4) return;
        if (xhttp.status == 201){
          let payload = JSON.parse(xhttp.responseText);
          if (payload['app_key'].length > 0 && payload['app_secret'].length > 0){
            localStorage.setItem("username", payload['username']);
            localStorage.setItem("appKey", payload['app_key']);
            localStorage.setItem("appSecret", payload['app_secret']);
            document.getElementById("email-box").value = "";
            document.getElementById("pass-box").value = "";
            document.getElementById("login-msg").innerHTML = '<font color="green">Get token success!</font>';
            document.getElementById('login').removeAttribute("disabled");
            document.getElementById("user-title").innerHTML = "Welcome! " + payload['username'];
            resolve("Get token success!");
          } else{
            document.getElementById("login-msg").innerHTML = '<font color="red">No token! Please login web and create new secret.</font>';
            document.getElementById('login').removeAttribute("disabled");
            reject("Can not get token!");
          }
        } else {
          document.getElementById("login-msg").innerHTML = '<font color="red">Get token failed!</font>';
          document.getElementById('login').removeAttribute("disabled");
          reject("Can not get token!");
        }
      };

      xhttp.open("POST", url);
      xhttp.setRequestHeader("Content-Type", "application/json");
      var data = "{\r\n    \"email\": \"" + email + "\",\r\n    \"password\": \"" + pass + "\"\r\n}";
      xhttp.send(data);
    });
  } else {
    document.getElementById("login-msg").innerHTML = '<font color="red">Please input EMAIL and PASSWORD!</font>';
    document.getElementById('login').removeAttribute("disabled");
    return "Invalid input!";
  }
}

export async function loadSecret(){
  const username =localStorage.getItem("username");
  const appKey = localStorage.getItem("appKey");
  const appSecret = localStorage.getItem("appSecret");
  console.log(appSecret);
}
